
























import { Component, Vue } from 'vue-property-decorator';
import { Response } from '@/types/apiResData'
import { constant, api, auth } from '@/utils'


@Component({})
export default class AppointmentSuccess extends Vue {
  private imageUrl = constant.IMAGE_URL
  private orderId = ''
  private orderDetail = []

  mounted() {
    const query = this.$route.query
    auth.isShowAuthLogin().then(() => {
      if(query && query.orderId) {
        this.orderId = query.orderId.toString()
        this.getOrder()
      }
    })
  }

  getOrder() {
    api.getOrder({orderId: this.orderId},(res: Response) => {
      const content = res.Content
      this.orderDetail = content
    })
  }

}
