













import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class BottomMenu extends Vue {
  bottomMenus?: any = [
    {
      isActive:1,
      activeImg:require('@/assets/imgs/home-active.png'),
      img:require('@/assets/imgs/home.png'),
      path:'/appointment',
      text:'首页'
    },
    {
      isActive:2, 
      activeImg:require('@/assets/imgs/order-active.png'),
      img:require('@/assets/imgs/order.png'),
      path:'/order/list',
      text:'订单'
    },
    {
      isActive:3, 
      activeImg:require('@/assets/imgs/service-active.png'),
      img:require('@/assets/imgs/service.png'),
      path:'/service/consult',
      text:'咨询客服'
    },
  ]

  get showMenu() {
    return this.$route.meta.bottomMenu.showMenu
  }

  get isActive() {
    return this.$route.meta.bottomMenu.isActive
  }

  navigate(data: number): void {
    if(data != this.bottomMenus[data].isActive - 1) {
      this.$router.replace({
        path: this.bottomMenus[data].path
      })
    }
  
  }
}
