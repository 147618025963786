




import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component
export default class CTransitionBg extends Vue {
  @Prop({default: false}) show!: boolean

  @Emit('close') close() {return false}
}
