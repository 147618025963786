





























import { Component, Vue, Watch, Inject } from 'vue-property-decorator';
import { Response, OrderRes } from '@/types/apiResData'
import { GetOrderListEntity } from '@/types/apiReqData'
import { CTopTabs, CLoadMore } from '@/components/ui'
import { api, auth, constant, util, tip } from '@/utils'

@Component({
  components: {
    CTopTabs,
    CLoadMore
  }
})
export default class OrderList extends Vue {
  @Inject('reload') reload!: Function
  private imageUrl = constant.IMAGE_URL
  private enableLoadMore = true
  private showLoadMore = true
  private currentTab = 0
  private orderTabs = [
    {text: '全部'},
    {text: '待取件'},
    {text: '已取件'},
    {text: '已完成'},
    {text: '已取消'}
  ]
  private entity: GetOrderListEntity = {
    pageIndex: 1, 
    pageSize:10, 
    keyword:'', 
    extInfo: {status: '', statusLG:'', channelIn:'wxMini,alipayMini,newH5wx,newH5App,newH5Alipay,community_wxMini'}
  }

  private orderList: Array<OrderRes> = []
  private noList = false

  get subchannel() {
    return this.$store.getters.getSubChannel
  }

  created() {
    auth.isShowAuthLogin().then(() => {
      this.getOrderList(this.entity)
    }).catch(() => {
      this.noList = true
    })
  }

  copy(id: string) {
    console.log(id)
    util.clipboard(id)
  }

  selectTab(index: number) {
    util.backTopClick()
    this.entity.pageIndex = 1
    this.entity.extInfo.status = index === 0?undefined:index === 3?undefined:index === 4?-1:index
    this.entity.extInfo.statusLG = index === 3?3:''
    this.currentTab = index
    this.getOrderList(this.entity)
  }

  getOrderList(data: GetOrderListEntity, done?: Function) {
    api.getOrderList(data, (res: Response) => {
      const content = res.Content
      
      if(data.pageIndex == 1 && content.length === 0) {
        this.showLoadMore = false
        this.noList = true
        return this.orderList = []
      }

      if(data.pageIndex == 1 && content.length > 0) {
        this.showLoadMore = content.length < 3?false:true
        return this.orderList = content
      }

      if(data.pageIndex > 1 && content.length === 0) {
        this.showLoadMore = true
        return this.enableLoadMore = false
      }

      if(data.pageIndex > 1 && content.length > 0) {
        this.showLoadMore = true
        this.orderList = [...this.orderList, ...content]
        console.log(this.orderList)
        return done && done()
      }
    },(mes: string) => {
      mes && tip.toast(mes)
      this.noList = true
    })
  }

  onLoadMore(done: Function) {
    setTimeout(()=>{
      if(!this.enableLoadMore) return
      ++this.entity.pageIndex
      this.getOrderList(this.entity, done)
    }, 200)
  }
}
