





import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CToast extends Vue {
    @Prop({default:''}) text!: string
    @Prop({default: false}) showWrap!: boolean
    @Prop({default: false}) showContent!: boolean

}
