
















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { api, auth } from '@/utils'
import { CBottomSheet,  CTopTabs } from './ui'

@Component({
  components: {
    CBottomSheet,
    CTopTabs
  },
})
export default class AddressSelect extends Vue {
  @Prop({default: false}) show!: boolean
  private height = 790
  private currentTab = 0
  private areaCodeText = ''

  private areaTabs = [
    {text: '省/直辖市', code:'Area_China', name:'', areaList: []},
    {text: '市 ', code:'', name:'', areaList: []},
    {text: '区/县 ', code:'', name:'', areaList :[]},
    {text: '街道/镇 ', code:'', name:'', areaList: []}
  ]

  created() {
    auth.isShowAuthLogin().then(() => {
      this.getRegionTownList(this.areaTabs[this.currentTab].code)
    })
  }

  get bottom() {
    return this.show?0:-790
  }

  @Emit('close') close() { 
    let name = ''
    this.areaTabs.map(i => name += i.name)   
    return {
      code:this.areaTabs[this.currentTab].code,
      name:name
    }
  }

  selectTab(index: number) {
    if(!this.areaTabs[index].code) return
    this.currentTab = index
  }

  selectArea(code: string, name: string) {
    this.areaTabs[this.currentTab].name = name
    if(this.currentTab === 3) return this.close()
    this.areaTabs[++this.currentTab].code = code
    this.getRegionTownList(code)
  }

  getRegionTownList(code: string) {
    api.getRegionTownList({parentId: code}, (res: any) => {
      const content = res.Content
      this.areaTabs[this.currentTab].areaList = content
    })
  }
}
