












import { Component, Vue, Provide, Watch } from 'vue-property-decorator'
import { BottomMenu, Login } from '@/components'
import { userAgent, api, auth } from '@/utils'

@Component({
  name:"App",
})
export default class App extends Vue {
  @Provide('reload') reload = this.pageReload
  private transitionRouter = ''
  private isRouterAlive = true

  created() {
    this.usePlaform()
  }

  mounted() {
    const query = this.$route.query
    if(query && query.subChannel == 'cityServices') {
      auth.wxAuth()
    }
  }

  get bottomMenuShow() {
    return this.$route.meta.bottomMenu && this.$route.meta.bottomMenu.show && this.$store.getters.getSubChannel !== 'cityServices'
  }

  get keepAlive() {
    return this.$route.meta.keepAlive
  }

  get requireAuth() {
    return this.$route.meta.requireAuth
  }

  get loginShow() {
    return this.$store.getters.getLoginShow
  }

  @Watch('$route')
  changeTransitionName(to: any, from: any) {
    console.log(this.$route.meta.bottomMenu)
    if(this.$route.meta.bottomMenu) return this.transitionRouter = ''

    const prev = from.meta.time || 0
    const next = to.meta.time || 0
    this.transitionRouter = prev > next?'slide-right':'slide-left'
  }

  pageReload() {
    this.isRouterAlive = false
    this.$nextTick(() => {
      this.isRouterAlive = true
    })
  }

  usePlaform() {
    if(userAgent.platform() == 'newH5wx' || userAgent.platform() == 'newH5Alipay' || userAgent.platform() == 'newH5App') return
    this.$router.replace({
      path: '/error',
      query:{
        title:'错误页面',
        code:'',
        showIndex: 'false',
        text:'请在微信端打开'
      }
    })
  }
}
