import { tip } from '.';

export function dateFormat(date: any, fmt: string) {
	const o: any = {
		"M+": date.getMonth() + 1, // 月份
		"d+": date.getDate(), // 日
		"h+": date.getHours(), // 小时
		"m+": date.getMinutes(), // 分
		"s+": date.getSeconds(), // 秒
		"q+": Math.floor((date.getMonth() + 3) / 3), // 季度
		"S": date.getMilliseconds() // 毫秒
	};
	if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
	for (const k in o) {
		if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)))
	}
	return fmt;
}


export function setCookie(name: string, value: string) {
	const Days = 30;
	const exp = new Date();
	exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
	document.cookie = name + "=" + escape(value) + ";path=/;expires=" + exp.toUTCString();
	return value;
}

// get cookie
export function getCookie(name: string) {
	// console.log(document.cookie);
	const reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
	const arr = document.cookie.match(reg);
	if (arr) {
		return unescape(arr[2])
	} else {
		return null
	}
}

export function isJSON(str: any) {
	if(typeof str == 'string') {
		try {
			const obj = JSON.parse(str)
			return typeof obj === 'object' && obj? true: false
		} catch(e) {
			return false
		}
	}
}

// set localStorage
export function setLocalStorage(name: string, value: string) {
	if(typeof value != 'string') value = JSON.stringify(value)
	localStorage.setItem(name, value)
	return value
}

// get localStorage
export function getLocalStorage(name: string) {
	const value: string | null =  localStorage.getItem(name)
	if(typeof value != 'string') return value
	return isJSON(value)? JSON.parse(value):value
}

// remove localStorage
export function removeLocalStorage(name: string) {
	localStorage.removeItem(name)
}

export function addDay(date: any, days: number) {
	const d = new Date(date);
	d.setDate(d.getDate() + days);
	return d
}

export function getDateNum(date: any) {
	const y = date.getFullYear();
	let m = date.getMonth() + 1;
	m = m < 10 ? '0' + m : m;
	const d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
	const keep = y + '' + m + '' + d;
	parseInt(keep)
	return keep; //20160614
}

//是否是当天
export function isCurrentDay(date: any) {
	const now = new Date()
	const date1 = new Date(date)
	const nowNum = parseInt(getDateNum(now))
	const dateNum = parseInt(getDateNum(date1))
	// console.log(nowNum)
	// console.log(dateNum)
	if(dateNum != nowNum) return false
	return true
}

export function backTopClick() {
	//window.scrollTo(0,0)
	document.documentElement.scrollTop = document.body.scrollTop = 0
}

//添加重量单位
export function addWeightUnit(weight: string | number) {
	if(weight === null || weight === undefined) return
	weight = typeof weight == 'number'? weight.toString():weight
	const weightArr = weight.split('-')
	if(weightArr.length === 1) {
		if(weightArr[0].indexOf('kg') > -1 || weightArr[0].indexOf('KG') > -1) return weight
		return weightArr[0] + 'KG'
	}
	if(weightArr.length === 2) {
		if(weightArr[1].indexOf('kg') > -1 || weightArr[1].indexOf('KG') > -1) return weight
		return weight + 'KG'
	}
}


export function clipboard(data: string) {
	let textArea: any = document.createElement('textarea')
	textArea.value = data
	document.body.appendChild(textArea)
	textArea.select()
	try {
		const success = document.execCommand('copy')
		if(!success) tip.toast('无法复制')
		tip.toast('复制成功')
	} catch(err) {
		tip.toast('复制失败')
	}
	document.body.removeChild(textArea)
	textArea = null
}