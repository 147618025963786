






























import { Component, Vue } from 'vue-property-decorator';
import { Response, OrderRes } from '@/types/apiResData'
import { constant, api, auth, util } from '@/utils'


@Component({})
export default class OrderDetail extends Vue {
  private imageUrl = constant.IMAGE_URL
  private orderId = ''
  private orderDetail: OrderRes = {
    address: '',
    pickUpTime: '',
    weightNew: '',
    logisticWeight: 0,
    orderStatus: 0,
    logisticStatus: 0,
    extInfo: null
  }
  private isCurrentDay = false
  private headerStatus = ''

  mounted() {
    const query = this.$route.query
    auth.isShowAuthLogin().then(() => {
      if(query && query.orderId) {
        this.orderId = query.orderId.toString()
        this.getOrder()
      }
    })
  }

  cannelOrder() {
    api.cancelOrders({orderId: this.orderId},() => {
      this.orderDetail.orderStatus = -1
    })
  }

  getOrder() {
    api.getOrder({orderId: this.orderId},(res: Response) => {
      const content = res.Content
      if(content) {
        this.orderDetail = content
        this.isCurrentDay = util.isCurrentDay(content.time)
        content.weightNew =  content.logisticWeight == 0?util.addWeightUnit(content.weight):util.addWeightUnit( content.logisticWeight)
        this.getHeaderStatus(this.orderDetail)
      }
    })
  }

  getHeaderStatus(orderDetail: OrderRes) {
    if(orderDetail.orderStatus === 1 && orderDetail.logisticStatus < 2) {
      this.headerStatus = '预约成功'
    } else if(orderDetail.orderStatus === -1) {
      this.headerStatus = '订单已取消'
    } else if(orderDetail.orderStatus === 1 && orderDetail.logisticStatus >= 2) {
      this.headerStatus = '待快递小哥上门取件'
    } else if(orderDetail.orderStatus === 2 && orderDetail.logisticWeight === 0) {
      this.headerStatus = '已取件，请等待回传重量'
    } else if(orderDetail.orderStatus == 2 && orderDetail.logisticWeight > 0) {
      this.headerStatus = '已取件，请等待完成'
    } else if(orderDetail.orderStatus >= 3 && orderDetail.logisticWeight === 0) {
      this.headerStatus = '回收成功，请等待传回重量'
    } else {
      this.headerStatus = '订单已完成'
    }
  }
}
