









import { Component, Vue, Prop } from 'vue-property-decorator';
import CLoadingIcon from './CLoadingIcon.vue'

@Component({
    components:{
        CLoadingIcon
    }
})
export default class CLoading extends Vue {
    @Prop({default:'加载中...'}) text!: string
    @Prop({default: false}) show!: boolean

}
