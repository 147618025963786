






















import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { util } from '@/utils'
import { CBottomSheet } from './ui'

@Component({
  components: {
    CBottomSheet,
  }
})
export default class TimeSelect extends Vue {
  @Prop({default: false}) show!: boolean

  @Emit('getDateArr')
  getDateArr(data: any) {
    return data
  }

  @Emit('close')
  close(data: boolean) {
    return data
  }

  get bottom() {
    return this.show?0:-840
  }

  private height = 840
  private days: any = []
  private disableCount = 0
  private weeks: any = []
  private activeDay: number | string = 0
  private times = ['9:00-11:00','11:00-13:00','13:00-15:00','15:00-17:00']
  private activeTime = 0

  mounted() {
    const days = this.setDaysRange()
    const weeks = this.setWeekRange(days)
    this.setActiveDay(0)
    this.days = days
    this.weeks = weeks
  }

  setDaysRange = () => {
    const days = [];
    const now = new Date();
    const hour  = now.getHours()
    let j = 0
    for (let i = 0; i < 7; i++) {
        //let d = ''
        // if(hour > 15 && hour < 24) {
        //     d = util.addDay(now, i + 1)
        // } else {
          let  d = util.addDay(now, i)
        // }
        let date = util.dateFormat(d, "yyyy-MM-dd")
        //判断是不是2021年02月10至2020年02月17
        const dateArr = date.split('-')
        const month = parseInt(dateArr[1])
        const day = parseInt(dateArr[2])
        if (month == 2 && day >= 10 && day <= 17) {
          d = util.addDay('2021-02-18', j)
          date = util.dateFormat(d,"yyyy-MM-dd")
          j++
        }
        days.push(date)
    }
    return days
  }

  setWeekRange(days: any) {
    //日期为输入日期，格式为 2016-8-10
    const weeks = []
    const now = new Date()
    const nowWeek = now.getDay()
    const weekArray = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"]
    for(const i in days) {
        const daysArr = days[i].split('-')
        const ndate = new Date(daysArr[0],daysArr[1]-1,daysArr[2])
        let weekDay = weekArray[ndate.getDay()];
        if(ndate.getDay() == nowWeek) {
            weekDay = '今日'
        }
        weeks.push(weekDay)
    }
    return weeks
  }

  setActiveDay(key: number) {
    this.activeDay = key
    this.setTimeDisable()
  }

  setActiveTime(key: number){
    this.activeTime = this.disableCount > key? this.disableCount : key
  }

  setTimeDisable() {
    let disableCount = 0
    const now = new Date()
    const day = now.getDate()
    const hour = now.getHours()
    for(const i in this.days) {
        if(this.activeDay == i && parseInt(this.days[i].split('-')[2]) == day) {
            if(hour >= 10 && hour < 12) {
                disableCount= 1
            }
            if(hour >= 12 && hour < 14) {
                disableCount = 2
            }
            if(hour >= 14 && hour < 16) {
                disableCount = 3 
            }
            if(hour >= 16){
                disableCount = 4
            }
        }
    }
    this.disableCount = disableCount
    this.setActiveTime(0)
    return disableCount
  }

  sure() {
    const day = []
    for(const i in this.days) {
        if(i == this.activeDay) {
            day[0] = this.days[i]
        }
    }
    for(const i in this.times) {
        if( this.activeTime.toString() == i) {
            const timeArr = this.times[i].split('-')
            day[1] = timeArr[0]
            day[2] = timeArr[1]
        }
    }
    this.getDateArr(day)
  }
}
