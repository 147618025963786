import { VueConstructor } from 'vue/types/umd'
import ToastComponent from '@/components/ui/CToast.vue'


interface Options {
    text?: string;
    duration?: number;
}

let toastDom: any = null
let delayTime: any = null

const plugin = {
    install (vue: VueConstructor, options: Options) {
      if(!toastDom) {
        const vueToast = vue.component('Toast', ToastComponent)
        const newToast = new vueToast
        toastDom = newToast.$mount()
        document.body.appendChild(toastDom.$el)
      }
      const toast = (options: Options = {}) => {
        toastDom.text = options.text
        toastDom.showWrap = true
        toastDom.showContent = true
        delayTime && clearTimeout(delayTime)
        delayTime = setTimeout(() => {
            toastDom.showWrap = false
            toastDom.showContent = false
            toastDom.text = ''
            clearTimeout(delayTime)
          }, options.duration || 1500)
      }
      
      if (!vue.$vmine) {
        vue.$vmine = {
          toast
        }
      } else {
        vue.$vmine.toast = toast
      }
    }
  }


export default plugin
export const install = plugin.install