



























import { Component, Vue } from 'vue-property-decorator'
import { CSlideDelete } from '@/components/ui'
import { constant, api, auth, tip } from '@/utils'

@Component({
  components: {
    CSlideDelete
  },
  beforeRouteEnter(to, from, next) {
    if(from.name === 'appointmentIndex') {
      to.meta.backPath = from.path
    }
    next()
  }
})
export default class AddressList extends Vue {
  private imageUrl = constant.IMAGE_URL
  private list: any = []
  private listLen = 0
  private startX = 0

  created() {
    auth.isAuthLogin().then(() => {
      this.getAddressList()
    })
  }

  getAddressList() {
    api.getAddressList((res: any)=> {
      const content = res.Content
      this.list = content
      this.listLen = content.length
    })
  }

  toAddressAdd(id: string) {
    auth.isShowAuthLogin().then(() => {
      this.$router.push({
        path: '/address/add',
        query: {recordId: id}
      })
    })
  }

  selectAddress(id: string) {
    if(this.$route.meta.backPath) {
      this.$router.replace({
        path: this.$route.meta.backPath,
        query:{
          recordId: id
        }
      })
    }
  }

  deleteAddress(id: string) {
    api.deleteAddress({recordId:id},() => {
      for(const index in this.list) {
        if(this.list[index].recordID === id) {
          this.list.splice(index, 1)
          this.listLen = this.list.length
        }
      }
      tip.toast('删除成功')
    })
  }

  deleteClick(id: string) {
    Vue.$vmine.modal({
      text:'是否删除该地址',
      sure: () => {
        this.deleteAddress(id)
      },
      cancel: () => {
        console.log('取消')
      }
    })
  }
}
