











import { Component, Vue, Prop } from 'vue-property-decorator';
import { constant } from '@/utils'

@Component({
  beforeRouteEnter(to, from, next) {
    to.meta.title = to.query.title
    next()
  }
})
export default class Error extends Vue {
  private imageUrl = constant.IMAGE_URL
  private code = ''
  private text = ''
  private showIndex = false

  mounted() {
    const query = this.$route.query
    if(query && query.showIndex) {
     
      this.showIndex = query.showIndex.toString() === 'true'?true: false
    }
    if(query && query.text) {
      this.text = query.text.toString()
    }
    if(query && query.code) {
        this.code = query.code.toString()
    }
  }
}
