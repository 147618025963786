function platform(): any {
    const userAgent = navigator.userAgent

    const ua: any = userAgent.toLowerCase()
    if(/micromessenger/i.test(ua.toLowerCase())) return 'newH5wx'
    if(/alipay/i.test(ua.toLowerCase())) return 'newH5Alipay'
    if(/android/i.test(ua.toLowerCase()) || /iphone/i.test(ua.toLowerCase())) return 'newH5App'
    return 'h5'
}

export {
    platform
}