import { VueConstructor } from 'vue/types/umd'
import ModalComponent from '@/components/ui/CModal.vue'


interface Options {
    [key: string]: string | number | undefined | boolean | Function;
    title?: string;
    text?: string;
    cancelText?: string;
    sureText?: string;
    show?: boolean;
    sure?: Function;
    cancel?: Function;
}

let modalDom: any = null

const plugin = {
    install (vue: VueConstructor, options: Options) {
      if(!modalDom) {
        const vueModal = vue.component('Modal', ModalComponent)
        const newModal = new vueModal
        modalDom = newModal.$mount()
        document.body.appendChild(modalDom.$el)
      }
      const modal = (options: Options) => {
        for(const i in options) {
          modalDom[i] = options[i]?options[i]:modalDom[i]
        }
        modalDom.$on('cancel',() => {
            modalDom.show = false
            options && options.cancel && options.cancel()
        })

        modalDom.$on('sure',() => {
          modalDom.show = false
          options && options.sure && options.sure()
        })

        modalDom.show = true
      }
      
      if (!vue.$vmine) {
        vue.$vmine = {
          modal
        }
      } else {
        vue.$vmine.modal = modal
      }
    }
  }


export default plugin
export const install = plugin.install