import Vue from 'vue'
import store from '@/store'
import VueRouter, { RouteConfig } from 'vue-router'
import AppointmentIndex from '@/views/appointment/index.vue'
import AppointmentIntro from '@/views/appointment/intro.vue'
import AppointmentSuccess from '@/views/appointment/success.vue'
import OrderDetail from '@/views/order/detail.vue'
import OrderList from '@/views/order/list.vue'
import ServiceConsult from '@/views/service/consult.vue'
import AddressList from '@/views/address/list.vue'
import AddressAdd from '@/views/address/add.vue'
import Error from '@/views/error.vue'
import Failure from '@/views/failure.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/appointment',
    name: 'appointmentIndex',
    component: AppointmentIndex,
    meta: {
      title:'预约回收',
      requireAuth: true,
      keepAlive:false,
      bottomMenu:{
        show:true, //显示底部菜单
        isActive:1, //底部菜单激活序号
        showMenu:[0,1,2] //显示底部菜单对应的菜单序号
      },
    }
  },
  {
    path: '/appointment/intro',
    name: 'appointmentIntro',
    component: AppointmentIntro,
    meta: {
      title:'旧衣回收介绍',
      requireAuth: false,
      keepAlive:false,
      bottomMenu:false,
    }
  },
  {
    path: '/appointment/success',
    name: 'appointmentSuccess',
    component: AppointmentSuccess,
    meta: {
      title:'确认订单信息',
      requireAuth: true,
      keepAlive:false,
      bottomMenu:false,
    }
  },
  {
    path: '/order/detail',
    name: 'orderDetail',
    component: OrderDetail,
    meta: {
      title:'订单详情',
      requireAuth: true,
      keepAlive:false,
      bottomMenu:false,
    }
  },
  {
    path: '/order/list',
    name: 'orderList',
    component: OrderList,
    meta: {
      title:'我的订单',
      requireAuth: true,
      keepAlive:false,
      bottomMenu:{
        show:true, 
        isActive:2,
        showMenu:[0,1,2]
      },
    }
  },
  {
    path: '/service/consult',
    name: 'serviceConsult',
    component: ServiceConsult,
    meta: {
      title:'咨询客服',
      requireAuth: true,
      keepAlive:false,
      bottomMenu:{
        show:true, 
        isActive:3,
        showMenu:[0,1,2]
      },
    }
  },
  {
    path: '/address/add',
    name: 'addressAdd',
    component: AddressAdd,
    meta: {
      title:'新增地址',
      requireAuth: true,
      keepAlive:false,
      bottomMenu:false,
    }
  },
  {
    path: '/address/list',
    name: 'addressList',
    component: AddressList,
    meta: {
      title:'选择地址',
      requireAuth: true,
      keepAlive:false,
      bottomMenu:false,
    }
  },
  {
    path: '/error',
    name: 'error',
    component: Error,
    meta: {
      title:'',
      requireAuth: false,
      keepAlive:false,
      bottomMenu:false,
    }
  },
  {
    path: '/failure',
    name: 'failure',
    component: Failure,
    meta: {
      title:'授权失败',
      requireAuth: false,
      keepAlive:false,
      bottomMenu:false,
    }
  },
  {
    path: '*',
    redirect: {
      path:'/error',
      query:{
        title:'页面丢失了',
        code:'404...',
        showIndex:'true', 
        text:'页面丢失了，返回首页吧～'
      }
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  //路由发生变化修改页面标题
  if (to.meta.title) document.title = to.meta.title
  // 页面过渡效果时间设置
  if(!to.meta.time) to.meta.time = new Date().getTime()
  //跳转带上渠道
  if(to.query.subChannel) {
    store.dispatch('changeSubChannel', to.query.subChannel)
  }
  if(!to.query.subChannel && store.getters.getSubChannel) {
    return next({
      path: to.path,
      query: {
        ...to.query,
        subChannel: store.getters.getSubChannel
      }
    })
  }

  next()
})

export default router
