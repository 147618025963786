



















































import { Component, Vue } from 'vue-property-decorator';
import { CTopTabs } from '@/components/ui'
import { constant } from '@/utils'

@Component({
  components: {
    CTopTabs
  }
})
export default class ServerConsult extends Vue {
  private imageUrl = constant.IMAGE_URL
  private currentTab = 0
  private orderTabs = [
    {text:'常见问题'},
    {text:'快递问题'}
  ]
  private problemLists = [
    {
    title:'收衣问题', 
    show: true, 
    showAll:false,
    content: [{
      title:"Q:旧衣服去哪了?",
      content: "这个回收旧衣物主要是做环保回收，我们收到的旧衣服会经过分拣中心把旧衣物进行再生处理，生产为再生棉，隔音棉等等。部分衣服会出口到手工业落后的国家，所得资金会重新投入到回收行业中，重在资源的再生利用。"
    },{
      title:"Q:运费需要我支付吗?",
      content: "回收的整个流程由我们承担物流费用，您是不需要承担任何费用的，物流费用是使用月结编码进行付款，若遇到不熟悉业务的快递小哥收运费,告知走的是“月结”账户即可。如果需要快递员帮您包装，需要额外支付1元包装费哦。"
    },{
      title:"Q:对旧衣物有要求吗?",
      content: "我们的回收业务暂时包括衣服、鞋子（成双）、包包。以上回收物品如有破损是没有关系的，只要保持干爽就符合。（温馨提示：每单必须满5公斤，否则无效，会被拒收哟）。"
    },{
      title:"Q:除了旧衣其他能收吗?",
      content: "鞋子、衣服、包包、床单、手套、被罩也可以收。"
    },{
      title:"Q:需要提前多久预约?",
      content: "每天16:00前最快可以预约当天上门（一个账户一天只能下一个订单，重复预约会被系统自动取消哟）。"
    },{
      title:"Q:旧衣回收如何打包?",
      content: "您只需要简单打包方便快递人员提走就可以了，例如绳子，麻包袋之类的。如果您没有，可以在与快递员联系时叫他带上。如果需要快递员帮您包装，需要额外支付1元包装费哦。"
    },{
      title:"Q:回收注意事项？",
      content: "检查好衣服口袋。防止有贵重物品遗留在口袋里，以免造成不必要的损失。"
    },{
      title:"Q:修改时间或取消订单?",
      content: "您如果实在没有空，可以跟快递员联系协商修改取件时间或者回收订单列表页进行取消，你也可以联系客服4008788329进行取消。"
    },{
      title:"Q:回收之后可以退回吗？",
      content: "如果包裹在到达点未签收之前，可联系客服协助通知物流公司原路返回；如果包裹已经被签收，则视为已处理且无法退回，下单即表示您同意此规则。"
    }]
    },
    {
      title:'快递问题', 
      show: false, 
      showAll:false,
      content:[{
      title:"Q:快递员什么时候上门?",
      content: "在您预约上门日期当天，您手机会收到一条物流短信通知，里面有快递员电话，您可以跟他联系确定取件时间，也可以等他联系您，麻烦您保持电话畅通，如有不便也可以跟快递员协商改约时间。如果一直没有快递员联系您，你可以联系客服4008788329或进入鸥燕支付宝小程序进行在线咨询。"
      },{
        title:"Q:快递员上门收衣服时需要我提供身份证?",
        content: "由于邮管局规定各快递公司全面推进实名制，所以快递员选收件时可能要求您出示身份证，请理解，如您已经实名登记了就无需再出示证件。"
      },{
        title:"Q:快递员不知道寄到哪里?",
        content: "地址信息已通过网单发送到快递员PDA中，请告知快递员仔细查看他的网单。"
      },{
        title:"Q:所在区域没有覆盖?",
        content: "我们这边直接查看不到具体回收范围，只有客户下单预约，系统分配订单给物流公司，才知道知道快递员能否接单上门取件，但目前县镇村乡一般都无法取件，给您带来不便非常抱歉。"
      },{
        title:"Q:一天能下多少单",
        content: "快递员一单能取走5-100KG旧衣服，只要衣服重量在此范围内，下一单即可，请大家不要重复提交订单，重复提交系统会自动取消订单，多谢合作"
      }]
    }
  ]

  open(index: number) {
    this.problemLists[index].show = !this.problemLists[index].show
  }

  packUp(index: number) {
    this.problemLists[index].showAll = false
  }

  openAll(index: number) {
    this.problemLists[index].showAll = true
  }

  selectTab(index: number) {
    this.currentTab = index
  }

}
