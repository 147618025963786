









import { Component, Vue } from 'vue-property-decorator';
import { constant, tip } from '@/utils'

@Component({})
export default class AppointmentIntro extends Vue {
  private imageUrl = constant.IMAGE_URL

}
