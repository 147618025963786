





































import { Component, Vue } from 'vue-property-decorator';
import { Response } from '@/types/apiResData'
import { AddressSelect } from '@/components'
import { CSwitch } from '@/components/ui'
import { constant, api, auth, tip } from '@/utils'

@Component({
  components: {
    AddressSelect,
    CSwitch
  },
  beforeRouteEnter(to, from, next) {
    document.title = to.query.recordId? '更新地址': '添加地址'
    next()
  }
})
export default class AddressAdd extends Vue {
  private imageUrl = constant.IMAGE_URL
  private showAddress = false
  private recordId = ''
  private detail = []
  private entity = {
    name: '',
    gender: '1',
    mobile: '',
    areaCodeText: '请选择地区',
    areaCode: '',
    address: '',
    isDefault: '0',
    isDefaultFlag: false
  }


  mounted() {
    const query = this.$route.query
    if(query && query.recordId) {
      this.recordId = query.recordId.toString()
      auth.isShowAuthLogin().then(() => {
        this.getAddress()
      })
    }
  }

  get disabledBtn() {
    return this.entity.name && this.entity.gender && this.entity.mobile && this.entity.areaCodeText && this.entity.address && this.entity.areaCode
  }

  getAddress() {
    api.getAddress({recordId: this.recordId}, (res: Response)=> {
      const content = res.Content
      this.entity = {
        name: content.name,
        gender: content.gender,
        mobile: content.mobile,
        areaCodeText: content.areaCodeText,
        areaCode: content.areaCode,
        address: content.address,
        isDefault: content.isDefault,
        isDefaultFlag: content.isDefault === '1'? true: false
      }
    })
  }

  selectSex(data: string) {
    this.entity.gender = data
  }

  addressSelectClose(data: any) {
    this.entity.areaCode = data.code
    this.entity.areaCodeText = data.name
    this.addressSelectShow(false)
  }

  addressSelectShow(data: boolean) {
    this.showAddress = data
  }

  selectTab(index: number) {
    console.log(index)
  }

  changeDefaultAddress() {
    this.entity.isDefault = this.entity.isDefault === "0"?"1":"0"
    this.entity.isDefaultFlag = !this.entity.isDefaultFlag
  }

  submit() {
    if(!this.entity.name) return tip.toast('请输入姓名')
    if(!this.entity.gender) return tip.toast('请选择性别')
    if(!this.entity.mobile) return tip.toast('请输入手机号码')
    if(!this.entity.areaCodeText || !this.entity.areaCode) return tip.toast('请选择地区')
    this.recordId? this.updateAddress(): this.addAddress()
  }

  addAddress () {
    api.addAddress(this.entity, () => {
      this.$router.back()
    })
  }

  updateAddress () {
    const entity = Object.assign({}, this.entity,{recordID: this.recordId})
    api.updateAddress(entity, () => {
       this.$router.back()
    })
  }
}
