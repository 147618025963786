import Vue from 'vue'
import axios from 'axios'
import { DOMAIN } from './constant'
import router from '@/router/index'
import { loading, loaded, toast } from './tip'
import {setLocalStorage, getLocalStorage, removeLocalStorage} from './util'


axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
axios.defaults.baseURL = DOMAIN

axios.interceptors.request.use(config => {
    const token = getLocalStorage("token")
	if (token) {
		config.headers['token'] = token
	}
	return config;
}, error => {
	return Promise.reject(error);
})

axios.interceptors.response.use(response => {
	return response;
}, error => {
	return Promise.reject(error)
})

function post(url: string, data = {}, cb: Function, fb?: Function) {
    const setTimeloading = setTimeout(()=> {
        loading()
    }, 1000)
    const token = getLocalStorage('token')
    axios({
        url: url,
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'application/json;charset=utf-8', 'token': token }
    }).then( (res: any) => {
        loaded()
        clearTimeout(setTimeloading)
        console.log('post:response')
        if (res.status === 200) {
            if (res.headers.token) setLocalStorage('token', res.headers.token)

            if (res.data.Code === '202') {
                console.log('post未登陆或者过期！')
                //store.dispatch('changeLoginShow', true)
                removeLocalStorage('userInfo')
                removeLocalStorage('token')
                fb && fb()
            } else if (res.data.Code === '400') {
                if (fb) {
                    fb(res.data.Message ? res.data.Message : '发生错误')
                } else {
                    toast(res.data.Message ? res.data.Message : '发生错误')
                }
            } else {
                cb && cb(res.data);
            }
        } else {
            loaded();
            console.log('温馨提示','post请求失败:' + JSON.stringify(res.statusCode))
            router.push({
                path:'/error',
                query: {title: res.statusCode + '页面',code:res.statusCode + '...', text: res.Message + '，返回首页吧~', showIndex: 'true'}
            })
        }
    })
}

function get(url: string , cb: Function, fb?: Function ) {
    const setTimeloading = setTimeout(()=> {
        loading()
    }, 1000)
    loading()
    const token = getLocalStorage('token')
    axios({
        url: DOMAIN + url,
        method: 'GET',
        headers: { 'Content-Type': 'application/json;charset=utf-8', 'token': token }
    }).then((res: any) => {
        loaded()
        clearTimeout(setTimeloading)
        if (res.status === 200) {
            if (res.headers.token) setLocalStorage('token', res.headers.token)
            if (res.data.Code === '202') {
                console.log('post未登陆或者过期！')
                //store.dispatch('changeLoginShow', true)
                removeLocalStorage('userInfo')
                removeLocalStorage('token')
                fb && fb()
            }
            if (res.data.Code === '400') {
                console.log('get:400'+ res.data.Message )
                if (fb) {
                    fb(res.data.Message ? res.data.Message : '发生错误')
                } else {
                    toast(res.data.Message ? res.data.Message : '发生错误')
                }
            }
            if(res.data.Code === '200') {
                cb && cb(res.data)
            }
        } else {
            console.log('温馨提示','get请求失败:' + JSON.stringify(res.statusCode))
            router.push({
                path:'/error',
                query: {title: res.statusCode + '页面',code:res.statusCode + '...', text: res.Message + '，返回首页吧~', showIndex: 'true'}
            })
        }
    })
}

export {
    post,
    get
}