















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { CBottomSheet } from './ui'

@Component({
  components: {
    CBottomSheet
  },
})
export default class WeightSelect extends Vue {
  @Prop({default: false}) show!: boolean
  private weightArr = ['5-10KG(约10-20件)','10.1-17KG(约15-30件)','17.1-25KG(约30-40件)','25KG以上(约45件)']
  private current = 0
  private height = 530
  private weight = 10

  get bottom() {
    return this.show?0:-530
  }

  @Emit('close')
  close(data: boolean) {
    return data
  }

  @Emit('getWeightArr')
  getWeightArr(data: any) {
    return data
  }

  selectWeight(key: number) {
    this.current = key
    switch(key) {
      case 0:
        this.weight = 10;break;
      case 1:
        this.weight = 11;break;
      case 2:
        this.weight = 12;break;
      case 3:
        this.weight = 13;break;
    }
  }

  sure() {
    const data = {
      weightText: this.weightArr[this.current],
      weight: this.weight
    }
    this.getWeightArr(data)
  }

}
