







import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component
export default class CSwitch extends Vue {
    @Prop({default: '#4AA693'}) color!: string
    @Prop({default: false}) checked!: boolean

    get left() {
        return this.checked? 50: 0
    }

    @Emit('change') 
    checkedChange() {
        return this.checked
    }

}
