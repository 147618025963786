import Vue from 'vue'

function toast(text: string) {
    Vue.$vmine.toast({text:text})
}

function loading() {
    Vue.$vmine.loading.show()
}

function loaded() {
    Vue.$vmine.loading.hide()
}

export {
    toast,
    loading,
    loaded
}