


















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component
export default class CSlideDelete extends Vue {
    @Prop({default:130}) height!: number

    @Emit('deleteClick') deleteClick() { return }

    private startX = 0
    private left = 0

    touchstart(e: any) {
        this.startX = e.targetTouches[0].pageX
    }

    touchend(e: any) {
        const endX = e.changedTouches[0].pageX,
            dx = endX - this.startX
        if(Math.abs(dx) < 2) return
        if (endX < this.startX) {
            this.left = -170
        } else {
            this.left = 0
        }
    }
}
