










import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import CTransitionBg from './CTransitionBg.vue'

@Component({
   components: {
    CTransitionBg,
  }
})
export default class CBottomSheet extends Vue {
  @Prop({default: false}) show!: boolean
  @Prop({type:Number, default: -350}) bottom!: number
  @Prop({type:Number, default: 350}) height!: number

  @Emit('close') close() {return false}
}

