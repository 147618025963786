










import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import CLoadingIcon from './CLoadingIcon.vue'

@Component({
    components:{
        CLoadingIcon
    }
})
export default class CButton extends Vue {
    @Prop({default:'按钮'}) text!: string
    @Prop({default:'false'}) loading!: string

    @Emit('click') click() {
        if(!this.loading) return
    }

}
