var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-slide-delete",style:({
      height: _vm.height/2 + 'px'
  }),on:{"touchstart":_vm.touchstart,"touchend":_vm.touchend}},[_c('div',{staticClass:"c-slide-right",style:({
      height: _vm.height/2 + 'px',
      lineHeight: _vm.height/2 + 'px'
  }),on:{"click":_vm.deleteClick}},[_vm._v("删除")]),_c('div',{staticClass:"c-slide-left",style:({
      height: _vm.height/2 + 'px',
      left: _vm.left/2 + 'px'
  })},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }