









import { Component, Vue, Prop } from 'vue-property-decorator';
import { constant } from '@/utils'

@Component({})
export default class Failure extends Vue {
  private imageUrl = constant.IMAGE_URL
  private text = 'ddd'


  mounted() {
    const query = this.$route.query
    if(query && query.message) {
      this.text = query.message.toString()
    }
  }
}
