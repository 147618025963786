













import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class CLoadingIcon extends Vue {
    @Prop({default:30}) size!: number
    @Prop({default: 10}) borderWidth!: number
    @Prop({default: '#aaa'}) borderColor!: string
    @Prop({default: '#fff'}) borderActiveColor?: string
}
