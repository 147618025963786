













import { Component, Vue, Prop, Emit } from 'vue-property-decorator';


@Component
export default class CModal extends Vue {
    @Prop({default:false}) show!: boolean
    @Prop({default:'取消'}) cancelText!: string
    @Prop({default:'确定'}) sureText!: string
    @Prop({default:'温馨提示'}) title!: string
    @Prop({default:''}) text!: string

    @Emit('sure') sure() { return }
    @Emit('cancel') cancel() { 
        return 
    }

}
