var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-loading-icon",style:({
    width: _vm.size/2 + 'px',
    height: _vm.size/2 + 'px',
    borderWidth: _vm.borderWidth/2 + 'px',
    borderBottomWidth: _vm.borderWidth/2 + 'px',
    borderTopColor: _vm.borderActiveColor,
    borderLeftColor: _vm.borderActiveColor,
    borderRightColor: _vm.borderActiveColor,
    borderBottomColor: _vm.borderColor
})})}
var staticRenderFns = []

export { render, staticRenderFns }