import apiReqData from '@/types/apiReqData'
import apiResData from '@/types/apiResData'
import { post, get } from './http'

//预约下单
function appointment(data: apiReqData.AppointmentEntity, callback: Function, fallback: Function) {
    post('/api/app/orders/book', data, (res: apiResData.Response) => {
        callback && callback(res)
    },(mes: any) => {
        fallback && fallback(mes)
    })
}

//取消订单
function cancelOrders(data: apiReqData.CancelOrdersEntity, callback: Function) {
    post('/api/app/orders/cancelBook/' + data.orderId, {}, (res: apiResData.Response) => {
        callback && callback(res)
    })
}

//获取预约订单
function getOrder(data: apiReqData.GetOrderEntity, callback: Function) {
    get('/api/app/orders/getOrder/' + data.orderId, (res: apiResData.Response) => {
        callback && callback(res)
    })
}

//获取订单列表
function getOrderList(data: apiReqData.GetOrderListEntity, callback: Function, fallback: Function) {
    post('/api/app/orders/getList', data, (res: apiResData.Response) => {
        callback && callback(res)
    },(mes: any) => {
        fallback && fallback(mes)
    })
}

//获取预约默认地址
function getDefaultAddress(callback: Function) {
    get('/api/app/address/getDefault', (res: apiResData.Response) => {
        callback && callback(res)
    })
}

//检查地址是否超区
function checkOpenArea(data: apiReqData.CheckOpenAreaEntity, callback: Function) {
    get('/api/app/address/checkOpenArea/' + data.areaCode, (res: apiResData.Response) => {
        callback && callback(res)
    })
}

//发送验证码
function sendMobileCode(data: apiReqData.SendMobileCodeEntity, callback: Function) {
    post('/api/app/login/sendMobileCode/' + data.mobile, data, (res: apiResData.Response) => {
        callback && callback(res)
    })
}

// 绑定手机号登录
function appointmentLoginH5(data: apiReqData.AppointmentLoginH5Entity, callback: Function) {
    post('/api/app/login/appointmentLoginH5', data, (res: apiResData.Response) => {
        callback && callback(res)
    })
}

//添加地址
function addAddress(data: apiReqData.AddAddressEntity, callback: Function) {
    post('/api/app/address/add', data, (res: apiResData.Response) => {
        callback && callback(res)
    })
}

//地址更新
function updateAddress(data: apiReqData.UpdateAddressEntity, callback: Function) {
    post('/api/app/address/update', data, (res: apiResData.Response) => {
        callback && callback(res)
    })
}

//获取地址
function getAddress(data: apiReqData.GetAddressEntity, callback: Function) {
    get('/api/app/address/get/' + data.recordId, (res: apiResData.Response) => {
        callback && callback(res)
    })
}

//删除地址
function deleteAddress(data: apiReqData.DeleteAddressEntity, callback: Function) {
    post('/api/app/address/delete/' + data.recordId, data, (res: apiResData.Response) => {
        callback && callback(res)
    })
}

//获取地址列表
function getAddressList(callback: Function) {
    get('/api/app/address/getList', (res: apiResData.Response) => {
        callback && callback(res)
    })
}

//获取下级省市区
function getRegionTownList(data: apiReqData.GetRegionTownListEntity, callback: Function) {
    post('/api/app/region/getRegionByParent', data, (res: apiResData.Response) => {
        callback && callback(res)
    })
}

function getRankingList(data: apiReqData.GetRankingListEntity, callback: Function) {
    post('/api/h5/RankingList/getRankingList', data, (res: apiResData.Response) => {
        callback && callback(res)
    })
}


export {
    appointment,
    getOrder,
    getOrderList,
    getDefaultAddress,
    checkOpenArea,
    sendMobileCode,
    appointmentLoginH5,
    addAddress,
    updateAddress,
    getAddress,
    deleteAddress,
    getAddressList,
    getRegionTownList,
    cancelOrders,
    getRankingList
}