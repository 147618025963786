
























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { CButton, CTransitionBg } from './ui'
import { constant, tip, api, util, validate } from '@/utils'

@Component({
  name:'Login',
  components: {
    CButton,
    CTransitionBg
  }
})
export default class Login extends Vue {
  @Prop({default: false}) private show!: boolean
  @Prop({type: Function, default: undefined, required: false}) submit!: Function

  private imageUrl = constant.IMAGE_URL
  private entity = {
    mobile: '',
    code: ''
  }
  private  view = {
    codeText: "获取验证码",
    disabled: false,
    sendCode: false
  }
  private  lessTime = 0
  private showLogin = false
  private loginLoading = false

  //@Model('change', {type: Boolean}) checked!: boolean

  close() {
    this.$store.dispatch('changeLoginShow', false)
  }

  @Watch('entity.mobile')
  changeMobile(val: string, oldVal: string) {
    this.showLogin = this.entity.mobile && this.entity.code?true: false
  }

  @Watch('entity.code')
  changeCode(val: string, oldVal: string) {
   this.showLogin = this.entity.mobile && this.entity.code?true: false
  }

  djs() {
    if (this.lessTime > 0) {
      this.lessTime--;
    } else {
      this.view.disabled = false;
      this.view.codeText = "获取验证码";
      return;
    }
    this.view.disabled = true;
    this.view.codeText = "" + this.lessTime + "s后重发";
    setTimeout(this.djs, 1000);
  }

  sendMobileCode() {
    if(this.view.disabled) return 
    if (!this.entity.mobile) return tip.toast("请输入手机号")
    if (!validate.pattern.test(this.entity.mobile)) return tip.toast("手机号不正确")

    api.sendMobileCode({mobile:this.entity.mobile}, (res: any) => {
      tip.toast("验证码已发送")
      this.lessTime = 60
      setTimeout(this.djs, 1000)
      this.view.sendCode = true
    })
  }

  login() {
    if (!this.entity.mobile) return tip.toast("请输入手机号")
    if (!validate.pattern.test(this.entity.mobile)) return tip.toast("手机号不正确")
    if (!this.view.sendCode) return tip.toast("您还未获取验证码")
    if (!this.entity.code)  return tip.toast("请输入验证码")
    if (4 != this.entity.code.length) return tip.toast("验证码错误")
    if(!this.loginLoading) {
      this.loginLoading = true
      api.appointmentLoginH5({mobile: this.entity.mobile, code: this.entity.code},(res: any) => {
        const content = res.Content
        util.setLocalStorage('userInfo',content)
        this.$store.dispatch('changeLoginShow', false)
        this.loginLoading = false
        this.submit()
      })
    }
   
  }
}
