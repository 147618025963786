import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'lib-flexible/flexible.js'

import { LoadingPlugin, ToastPlugin, ModalPlugin } from '@/components/plugins/index'
import { BottomMenu, Login } from '@/components'

Vue.component('BottomMenu', BottomMenu)
Vue.component('Login', Login)

Vue.use(LoadingPlugin)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
