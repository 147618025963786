import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginShow: false,
    channel:'',
    subChannel: ''
  },
  getters: {
    getLoginShow: state => state.loginShow,
    getChannel: state => state.channel,
    getSubChannel: state => state.subChannel,
  },
  mutations: {
    changeLoginShow(state, show: boolean) {
      return state.loginShow = show
    },
    changeChannel(state, data: string) {
      return state.channel = data
    },
    changeSubChannel(state, data: string) {
      return state.subChannel = data
    }
  },
  actions: {
    changeLoginShow(context, show) {
      context.commit('changeLoginShow', show)
    },
    changeChannel(context, data: string) {
      context.commit('changeChannel', data)
    },
    changeSubChannel(context, data: string) {
      context.commit('changeSubChannel', data)
    }
  },
  modules: {
    
  }
})
