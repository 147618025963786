













import { Component, Vue, Prop } from 'vue-property-decorator'
import CLoadingIcon from './CLoadingIcon.vue'
import { util, tip } from '@/utils'

@Component({
    components:{
        CLoadingIcon
    }
})
export default class CLoadMore extends Vue {
    @Prop({type:Boolean,default: false}) enable!: boolean
    @Prop({type:Boolean,default: true}) show!: boolean
    // @Prop({type:Boolean,default: true}) showPullDownRefresh!: boolean
    @Prop({type:Function, default:undefined, required: false}) onLoadMore!: Function
    @Prop({type:Function, default:undefined, required: false}) reload!: Function

    private startX = 0
    private startY = 0
    private isLoading = false
    //private isRefreshLoading = false
    private loadMoreText = '上拉加载更多'
    // private loadRefreshText = '正在加载...'

    touchStart(e: any) {
      this.startX = e.targetTouches[0].pageX
      this.startY = e.targetTouches[0].pageY
    }

    touchEnd(e: any) {
      const endX = e.changedTouches[0].pageX,
            endY = e.changedTouches[0].pageY,
            dy = this.startY - endY,
            dx = endX - this.startX
      if(Math.abs(dx) < 2 && Math.abs(dy) < 2) return
      if (endY < this.startY) this.scrollToEnd(e)
    }

    doLoadMore() {
      this.isLoading = true
      this.loadMoreText = '正在加载中...'
      this.onLoadMore(this.loadDone)
    }

    loadDone() {
      this.isLoading = false
      this.loadMoreText = '上拉加载更多'
    }

    scrollToEnd(e: any) {
      const scrollHeight = this.$el.scrollHeight 
      const clientHeight = document.documentElement.clientHeight
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop + clientHeight >= scrollHeight && this.show) this.doLoadMore() 
    }

    // doRefresh() {
    //   this.isRefreshLoading =  true
    //   this.loadRefreshText = '正在加载...'
    //   this.reload(this.refreshDone)
    // }

    // refreshDone() {
    //    this.isRefreshLoading = false
    // }

    // scrollToTop(e: any) {
    //   const scrollTop = document.documentElement.scrollTop
    //   if(scrollTop === 0) this.doRefresh()
    // }

    backTopClick() {
      util.backTopClick()
      setTimeout(() => {
        this.reload()
      }, 300)
      
    }
}
