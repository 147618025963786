import { VueConstructor } from 'vue/types/umd'
import LoadingComponent from '@/components/ui/CLoading.vue'


interface Options {
    text?: string;
}

let loadingDom: any = null

const plugin = {
    install (vue: VueConstructor, options: Options = {text:'加载中...'}) {
      if(!loadingDom) {
        const vueLoading = vue.component('Loading', LoadingComponent)
        const newLoading = new vueLoading
        loadingDom = newLoading.$mount()
        document.body.appendChild(loadingDom.$el)
      }
      const loading = {
        show() {
          loadingDom.show = true
        },
        hide() {
          loadingDom.show = false
        }
      }
      
      if (!vue.$vmine) {
        vue.$vmine = {
          loading
        }
      } else {
        vue.$vmine.loading = loading
      }
    }
  }


export default plugin
export const install = plugin.install