








import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component({})
export default class CTopTabs extends Vue {
  @Prop() tabs!: object
  @Prop() current!: number
  @Prop() color!: string
  @Prop() colorActive!: string
  @Emit('selected')
  selected(index: number) {
    return index
  }

  topTabsItemStyle = {
    color: this.color,
    borderBottom: '2px solid transparent'
  }

  topTabsItemStyleActive = {
    color: this.colorActive,
    borderBottom: '2px solid ' + this.colorActive
  }
}
