import store from '@/store'
import { getLocalStorage, getCookie } from './util'
import { DOMAIN } from '@/utils/constant'

function isShowAuthLogin() {
    return new Promise((resolve: Function, reject: Function) => {
        const userInfo: any = getLocalStorage('userInfo')
        if(userInfo && userInfo.userId) {
            store.dispatch('changeLoginShow', false)
            resolve && resolve()
        } else {
            store.dispatch('changeLoginShow', true)
            reject && reject()
        }
    })
}

function isAuthLogin() {
    return new Promise((resolve: Function, reject: Function) => {
        const userInfo: any = getLocalStorage('userInfo')
        if(userInfo && userInfo.userId) {
            resolve && resolve()
        } else {
            reject && reject()
        }
    })
   
}

function wxAuth() {
    const auth = getCookie('auth')
    if(!auth) {
      window.location.href = DOMAIN + '/api/app/thirdPlatform/wxAuthLoginCityService?redirect=appointment?subChannel=cityServices'
    }
}

export {
    isAuthLogin,
    isShowAuthLogin,
    wxAuth
}