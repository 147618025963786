


















import { Component, Vue, Prop } from 'vue-property-decorator'

interface Rows {
    avatar: string;
    avatarSize: number;
    avatarShape: string;
    width: number;
    height: number;
    rows: Array<{
        width: number;
        height: number;
    }>;
}

@Component
export default class CSkeleton extends Vue {
    @Prop({default: false}) loading!: boolean
    @Prop({}) rows!: Array<Rows>

}
