




































































































































































import { Component, Vue, Inject, Watch } from 'vue-property-decorator'
import { AppointmentEntity, GetRankingListEntity } from '@/types/apiReqData'
import { Response } from '@/types/apiResData'
import { CLoadMore, CButton, CSkeleton } from '@/components/ui'
import { TimeSelect, WeightSelect } from '@/components'
import { constant, auth, api, util, tip, userAgent } from '@/utils'
import { platform } from '@/enums'

@Component({
  name: 'AppointmentIndex',
  components: {
    CLoadMore,
    CButton,
    CSkeleton,
    TimeSelect,
    WeightSelect
  }
})
export default class AppointmentIndex extends Vue {
  @Inject('reload') reload!: Function
  private enableLoadMore = true
  private showLoadMore = true
  private timeSelectShow = false
  private weightSelectShow = false
  private imageUrl = constant.IMAGE_URL
  private addressText = '请选择详细地址'
  private areaCode = ''
  private isAreaSupport = false
  private weightText = '请选择预估衣物重量'
  private rankingList = [...new Array(0)]
  private appointmentLoading = false
  private entity: AppointmentEntity = {
    weight: '',
    appointTime: '请选择预约时间',
    appointBeginTime:'',
    appointEndTime:'',
    addressId: '',
    channel:platform[userAgent.platform()],
    subchanel:'',
    organId:'',
    memo:''
  }
  private rankingEntity = {
    pageIndex: 1,
    pageSize: 5
  }

  created() {
    const query = this.$route.query
    this.getRankingList(this.rankingEntity)
    if(query && query.recordId) {
      this.entity.addressId = query.recordId.toString()
      auth.isAuthLogin().then(() => {
        this.getAddress()
      })
    } else {
      auth.isAuthLogin().then(() => {
        this.getDefaultAddress()
      })
    }
  }

  get subchannel() {
    return this.$store.getters.getSubChannel
  }

  
  get loginShow() {
    return this.$store.getters.getLoginShow
  }

  getAddress() {
    api.getAddress({recordId: this.entity.addressId}, (res: Response)=> {
      const content = res.Content
      if(content) {
        this.addressText = content.areaCodeText + content.address
        this.areaCode = content.areaCode
        this.checkOpenArea()
      }
      
    })
  }

  getDefaultAddress() {
    api.getDefaultAddress((res: Response) => {
      const content = res.Content
      if(content) {
        this.entity.addressId = content.recordID
        this.addressText = content.areaCodeText + content.address
        this.areaCode = content.areaCode
        this.checkOpenArea()
      }
    })
  }

  checkOpenArea() {
    this.isAreaSupport = false
    api.checkOpenArea({areaCode: this.areaCode},(res: Response) => {
        this.isAreaSupport = true
    })
  }

  onLoadMore(done: Function) {
    setTimeout(()=>{
      if(!this.enableLoadMore) return
      ++this.rankingEntity.pageIndex
      this.getRankingList(this.rankingEntity, done)
    }, 200)
  }

  getRankingList(data: GetRankingListEntity, done?: Function) {
    api.getRankingList(data, (res: Response) => {
      const content = res.Content
      if(this.rankingEntity.pageIndex === 1 && content.length > 0) {
        this.showLoadMore = true
        return this.rankingList = content
      }
      if(this.rankingEntity.pageIndex === 1 && content.length === 0) {
        return this.showLoadMore = false
      }
      if(this.rankingEntity.pageIndex > 1 && content.length === 0) {
        this.showLoadMore = true
        return this.enableLoadMore = false 
      }
      if(this.rankingEntity.pageIndex > 1 && content.length > 0) {
        this.showLoadMore = true
        this.rankingList = [...this.rankingList,...content]
        done && done()
      }
    })
  }

  showTimeSelectClick(data: boolean) {
    this.timeSelectShow = data
  }
  
  showWeightSelectClick(data: boolean) {
    this.weightSelectShow = data
  }

  showAddressListClick() {
    this.$router.push({
      path:'/address/list'
    })
  }

  getDateArr(data: any) {
    this.entity.appointTime = data[0]
    this.entity.appointBeginTime = data[1]
    this.entity.appointEndTime = data[2]
    this.showTimeSelectClick(false)
  }

  getWeightArr(data: any) {
    this.entity.weight = data.weight
    this.weightText = data.weightText
    this.showWeightSelectClick(false)
  }

  appointment() {
    auth.isShowAuthLogin().then(() => {
      this.entity.subchanel = this.$store.getters.getSubChannel
      if(this.entity.subchanel == 'cityServices') this.entity.organId = '00000000037'
      if(!this.isAreaSupport) return tip.toast("当前地址暂未开通服务")
      if(!this.entity.addressId) return tip.toast('请选择地址')
      if(!this.entity.appointTime || !this.entity.appointBeginTime || !this.entity.appointEndTime) return tip.toast('请选择预约时间')
      if(!this.entity.weight) return tip.toast('请选择预估衣物重量')
      if(!this.entity.channel) return tip.toast('找不到该渠道，请退出后重试')
      if(!this.appointmentLoading) {
        this.appointmentLoading = true
        //console.log(this.entity)
        api.appointment(this.entity, (res: Response) => {
          const content = res.Content
          this.appointmentLoading = false
          this.$router.push({
            path: '/appointment/success?orderId=' + content.orderId
          })
        },(mes: string) => {
          this.appointmentLoading = false
          tip.toast(mes)
        })
      }
    })
  }
}
